import React, { PropsWithChildren, useCallback } from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Image, { FluidObject } from 'gatsby-image';
import { Logger } from 'utils/loggers';
import History from './History';
import { format } from 'date-fns';

const useStyles = makeStyles<Theme>(({ spacing, typography, palette, breakpoints }) => ({
  root: {
    marginBottom: spacing(6),
  },
  logo: {
    minWidth: 50,
    marginRight: spacing(2),
    [breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  titleLink: {
    textDecoration: 'none',
    transition: 'opacity .2s ease-in-out',
    '&:hover': {
      opacity: 0.6,
    },
  },
  title: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.h6.fontSize,
    color: palette.text.primary,
  },
  date: {
    marginLeft: spacing(1),
  },
  description: {
    fontSize: typography.caption.fontSize,
    marginBottom: spacing(1),
  },
  role: {
    fontSize: typography.caption.fontSize,
    color: palette.text.secondary,
  },
  linkButton: {
    padding: spacing(1),
    marginLeft: spacing(1),
  },
}));

interface Props {
  logo: FluidObject;
  title: string;
  role: string;
  description: string;
  startDate: Date;
  endDate?: Date;
  link: string;
  logger?: Logger;
}
const OrganizationInfo = ({
  logo,
  title,
  description,
  startDate,
  endDate,
  role,
  link,
  children,
  logger,
}: PropsWithChildren<Props>) => {
  const styles = useStyles();
  const formattedStartDate = format(startDate, 'yyyy.MM');
  const formattedEndDate = endDate != null ? format(endDate, 'yyyy.MM') : '현재';

  const handleExternalLinkClick = useCallback(() => {
    logger?.click('click_external_org_link', { target: title });
  }, [logger, title]);

  return (
    <Grid className={styles.root} container>
      <Grid item>
        <Image className={styles.logo} fluid={logo} />
      </Grid>
      <Grid item xs={10}>
        <Box display="flex" alignItems="center">
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleExternalLinkClick}
            className={styles.titleLink}
          >
            <Typography className={styles.title} component="h3">
              {title}
            </Typography>
          </a>
          <Typography variant="caption" className={styles.date}>
            {formattedStartDate} ~ {formattedEndDate}
          </Typography>
        </Box>
        <Typography className={styles.description}>{description}</Typography>
        <Typography className={styles.role}>{role}</Typography>
        <div>{children}</div>
      </Grid>
    </Grid>
  );
};

OrganizationInfo.History = History;

export default OrganizationInfo;
