import React, { ReactNode } from 'react';
import { Box, Typography, Divider, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ spacing, palette }) => ({
  root: {
    marginTop: spacing(10),
  },
  title: {
    fontWeight: 'bold',
  },
  divider: {
    width: '10%',
    backgroundColor: palette.text.primary,
    height: 3,
    margin: `${spacing(4)}px 0 ${spacing(6)}px`,
  },
}));

interface Props {
  title: string;
  description?: ReactNode;
}
const SectionTitle = ({ title, description }: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Typography variant="h4" component="h2" className={styles.title} gutterBottom>
        {title}
      </Typography>
      {description && <Typography>{description}</Typography>}
      <Divider className={styles.divider} />
    </Box>
  );
};

export default SectionTitle;
