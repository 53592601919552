import React, { useCallback, useMemo } from 'react';
import * as Icon from 'react-feather';
import { IconButton, Tooltip } from '@material-ui/core';
import { Link } from './models';

const iconSize = 16;

const ExternalLink = ({ link, onClick }: { link: Link; onClick?: (type: string) => void }) => {
  const handleClick = useCallback(() => {
    onClick?.(link.type);
  }, [link.type, onClick]);

  const data = useMemo(() => {
    switch (link.type) {
      case 'github':
        return {
          title: '소스보기',
          icon: <Icon.Code size={iconSize} />,
        };
      case 'demo':
        return {
          title: '데모보기',
          icon: <Icon.Play size={iconSize} />,
        };
      case 'shop':
        return {
          title: '구매하기',
          icon: <Icon.ShoppingCart size={iconSize} />,
        };
      case 'youtube':
        return {
          title: '보러가기',
          icon: <Icon.Youtube size={iconSize} />,
        };
      case 'posting':
        return {
          title: '관련 포스팅 보러가기',
          icon: <Icon.ExternalLink size={iconSize} />,
        };
    }
  }, [link.type]);

  return (
    <IconButton href={link.url} onClick={handleClick} target="_blank">
      <Tooltip title={data.title} placement="top">
        {data.icon}
      </Tooltip>
    </IconButton>
  );
};

export default ExternalLink;
