import React, { PropsWithChildren, useCallback } from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link } from '../models';
import { Logger } from 'src/utils/loggers';
import ExternalLink from '../ExternalLink';

const useStyles = makeStyles<Theme>(({ spacing, typography, palette }) => ({
  root: {
    paddingLeft: spacing(2),
    marginTop: spacing(2),
    borderLeft: `4px solid ${palette.grey[300]}`,
  },
  titleWrapper: {
    marginBottom: spacing(0.5),
  },
  title: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.body2.fontSize,
    marginRight: spacing(0.5),
  },
}));

interface Props {
  title: string;
  links?: Link[];
  logger?: Logger;
}
const History = ({ title, children, links, logger }: PropsWithChildren<Props>) => {
  const styles = useStyles();

  const handleExternalLinkClick = useCallback(
    (type: string) => {
      logger?.click(`click_external_link`, {
        product: title,
        type,
      });
    },
    [logger, title]
  );

  return (
    <Box className={styles.root}>
      <Box display="flex" alignItems="center" className={styles.titleWrapper}>
        <Typography className={styles.title}>{title}</Typography>
        <Grid container item xs>
          {links?.map(link => (
            <ExternalLink key={link.url} link={link} onClick={handleExternalLinkClick} />
          ))}
        </Grid>
      </Box>
      <Typography variant="body2">{children}</Typography>
    </Box>
  );
};

export default History;
