import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { Box, Link, makeStyles } from '@material-ui/core';
import SectionTitle from './SectionTitle';
import { Post } from 'models/post';
import { GroupItem } from 'models/query';
import { generateLogger } from 'utils/loggers';
import IntroSection from './IntroSection';
import OrganizationInfo from './OrganizationInfo';
import ProjectItem from './ProjectItem';
import { useRelatedProjectPosts } from 'hooks/useRelatedProjectPosts';
import { graphql, useStaticQuery } from 'gatsby';
import { FluidImageSharp } from 'models/image';

const logger = generateLogger('about_page');
const handleExternalLinkClick = (target: string) => logger.click('click_external_link', { target });

const fieldValueFilter = (value: string) => (node: GroupItem<Post>) => node.fieldValue === value;

const useStyles = makeStyles({
  root: {
    maxWidth: 1024,
    margin: '0 auto',
  },
});

const AboutPage = () => {
  const styles = useStyles();
  const relatedProjectPosts = useRelatedProjectPosts();
  const imageData = useStaticQuery<{
    quotalabLogo: FluidImageSharp;
    tossLogo: FluidImageSharp;
    lubyconLogo: FluidImageSharp;
    soomgoLogo: FluidImageSharp;
    everyfridayLogo: FluidImageSharp;
  }>(graphql`
    query {
      quotalabLogo: file(absolutePath: { regex: "/logo/quotalab.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tossLogo: file(absolutePath: { regex: "/logo/toss.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lubyconLogo: file(absolutePath: { regex: "/logo/lubycon.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      soomgoLogo: file(absolutePath: { regex: "/logo/soomgo.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      everyfridayLogo: file(absolutePath: { regex: "/logo/everyfriday.jpeg/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { nodes: bookPosts } = relatedProjectPosts.filter(fieldValueFilter('vuejsBook'))[0];
  const { nodes: solarSystemPosts } = relatedProjectPosts.filter(fieldValueFilter('solarSystem'))[0];
  const { nodes: simpleAnnPosts } = relatedProjectPosts.filter(fieldValueFilter('simpleAnn'))[0];
  const { nodes: simpleWaveformPosts } = relatedProjectPosts.filter(fieldValueFilter('simpleWaveform'))[0];
  const { nodes: regexPosts } = relatedProjectPosts.filter(fieldValueFilter('regex'))[0];
  const { nodes: surviveMarketPosts } = relatedProjectPosts.filter(fieldValueFilter('surviveMarket'))[0];

  useEffect(() => {
    logger.view();
  }, []);

  return (
    <Layout profile={false} className={styles.root}>
      <SEO title="문동욱 | Evan Moon" />
      <IntroSection onClickExternalLink={handleExternalLinkClick} />
      <Box component="section">
        <SectionTitle title="Work" />
        <OrganizationInfo
          logo={imageData.tossLogo.childImageSharp.fluid}
          title="비바리퍼블리카(토스)"
          description="토스는 금융을 쉽고 간편하게 만들 수 있는 모든 것에 도전하는 금융 플랫폼입니다. 다양한 기술적 도전과 함께, 재미있게 일 하는 사람들이 만들어가는 문화가 어떤 것인지도 함께 경험하고 있습니다."
          startDate={new Date(2024, 1, 13)}
          role="Frontend Product Chapter Lead"
          link="https://toss.im/"
          logger={logger}
        >
          <OrganizationInfo.History title="Frontend Product Chapter Lead">
            토스의 B2C, B2B 대고객 서비스를 만드는 프론트엔드 개발자들이 모인 Frontend Product Chapter를 리딩하고
            있습니다.
          </OrganizationInfo.History>
        </OrganizationInfo>
        <OrganizationInfo
          logo={imageData.quotalabLogo.childImageSharp.fluid}
          title="쿼타랩"
          description="쿼타랩은 스톡옵션이나 RSU와 같은 복잡한 비상장 증권/금융 데이터나 법인 운영에 필요한 주주 명부 관리, 영업보고 등을 사용자들이 편하게 이해하고 관리할 수 있는 금융 플랫폼 쿼타북을 운영하고 있습니다."
          startDate={new Date(2022, 2, 1)}
          endDate={new Date(2023, 9, 30)}
          role="Frontend Chapter Lead"
          link="https://www.quotabook.com/ko"
          logger={logger}
        >
          <OrganizationInfo.History
            title="Frontend Chapter Lead"
            links={[{ type: 'posting', url: 'https://evan-moon.github.io/2023/10/03/how-to-be-a-better-leader/' }]}
          >
            쿼타랩 팀 내에 챕터라는 조직의 개념을 처음 도입하고 프론트엔드 챕터 빌딩부터 운영에 대한 DRI를 맡았습니다.
            최고의 인재를 모셔 온전한 자율과 책임 기반의 문화를 구현하겠다는 미션을 달성하기 위해 적극적인 채용
            인플로우, 브랜딩, 프로세스 수립 등에 기여하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="VC Tribe / Engineering Manager">
            투자 딜, 펀드 관리, LP보고 등 VC의 심사역, 관리자 등이 포트폴리오를 편하게 관리하고 투자 대상 기업과의
            커뮤니케이션을 원활하게 할 수 있는 기능을 개발하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="Startup Tribe">
            투자 유치, 영업 보고, 주주 및 이사회 관리, 스톡옵션/RSU 부여 등 비상장 스타트업들이 금융/리걸 맥락에서
            느끼고 있는 어려움과 불편함을 제거하고 개선하였습니다.
          </OrganizationInfo.History>
        </OrganizationInfo>
        <OrganizationInfo
          logo={imageData.tossLogo.childImageSharp.fluid}
          title="비바리퍼블리카(토스)"
          description="토스는 금융을 쉽고 간편하게 만들 수 있는 모든 것에 도전하는 금융 플랫폼입니다. 다양한 기술적 도전과 함께, 재미있게 일 하는 사람들이 만들어가는 문화가 어떤 것인지도 함께 경험하고 있습니다."
          startDate={new Date(2019, 11, 1)}
          endDate={new Date(2022, 2, 27)}
          role="Frontend Engineer, F-Lead"
          link="https://toss.im/"
          logger={logger}
        >
          <OrganizationInfo.History title="F-Lead">
            토스 코어의 혁신 비즈니스를 담당하는 사일로에 소속된 프론트엔드 개발자들의 업무 생산성을 향상시키고 성장
            방향성을 잡는 것을 도와주는 리드 업무를 수행하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="Benefit Silo">
            토스를 사용하는 유저들에게 금융과 일상 혜택을 제공하는 만보기, 브랜드 캐시백, 행운퀴즈 등의 제품을
            개발하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="Biz Silo">
            이번 주 미션, 머니알림 등 혜택 탭 내에 들어가는 제품들과 토스의 멤버십 서비스인 토스 프라임을
            개발하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="Insurtech Silo">
            토스 앱 내의 내 보험 서비스를 개발하고, 토스 인슈어런스의 인터널 고객관리 솔루션 개발 및 AWS, Travis CI,
            Terraform를 사용한 프론트엔드 인프라 구축을 담당하였습니다.
          </OrganizationInfo.History>
        </OrganizationInfo>
        <OrganizationInfo
          logo={imageData.soomgoLogo.childImageSharp.fluid}
          title="브레이브모바일(숨고)"
          description="브레이브모바일은 온오프라인의 서비스 제공자와 소비자를 연결하는 O2O 플랫폼 숨고를 운영하고 있습니다."
          startDate={new Date(2017, 4, 1)}
          endDate={new Date(2019, 10, 30)}
          role="Frontend Engineer"
          link="https://soomgo.com/"
          logger={logger}
        >
          <OrganizationInfo.History
            title="기존 어플리케이션 PWA 기반 작업"
            links={[{ type: 'posting', url: 'https://evan-moon.github.io/2019/07/06/pwa-with-notification/' }]}
          >
            고수와 소비자가 숨고 서비스를 벗어나지 않고 앱 내부에서 커뮤니케이션을 이어나가도록 만들어 서비스 이탈율을
            낮추고 매칭 여부를 확인하기 용이하도록 Web Socket과 Notification API를 사용한 노티 기능을 추가하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History
            title="클라이언트 사이드 렌더링 최적화"
            links={[{ type: 'posting', url: 'https://evan-moon.github.io/2019/06/03/client-render-optimizing/' }]}
          >
            FCP를 개선하기 위해 Assets Caching 전략 개선, 번들 사이즈 감축, Critical Rendering Path 과정 등의 작업을
            수행하여 FCP를 70% 가량 개선하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="TypeScript 도입">
            기존에 있던 Vue 어플리케이션의 빌드 프로세스에 ts-loader를 추가하여 타입스크립트 빌드 프로세스를 구성하고
            타입 에러가 자주 나는 부분부터 순차적으로 타입 분석 커버리지를 늘림.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="Webpack3로 번들러 마이그레이션">
            처음 입사할 때 구성되어있던 gulp &#38; gulp-webpack 플러그인으로 구성되어있던 빌드 프로세스를 webpack만으로
            번들링과 트랜스파일링할 수 있게 마이그레이션하였습니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History title="Frontend SSR 서버 구축">
            Django에 종속되어있던 Frontend 어플리케이션을 별도의 어플리케이션으로 분리하고 Express를 사용하여 SSR 렌더
            서버를 직접 구현하였습니다. 당시 Vue 진영의 ISR 프레임워크는 성숙하지 않은 단계라 최소 기능만을 가진 렌더
            서버를 직접 구현하는 방향을 선택했습니다.
          </OrganizationInfo.History>
        </OrganizationInfo>
        <OrganizationInfo
          logo={imageData.everyfridayLogo.childImageSharp.fluid}
          title="공팔리터"
          description="공팔리터는 소비자가 직접 상품을 사용해보고 리뷰를 공유함으로써 판매자는 불필요한 광고 비용을 절약하고, 소비자는 합리적인 가격의 소비를 할 수 있도록 연결해주는 리뷰 플랫폼입니다."
          startDate={new Date(2016, 6, 1)}
          endDate={new Date(2017, 0, 30)}
          role="Frontend Engineer"
          link="https://biz.08liter.com/"
          logger={logger}
        >
          <OrganizationInfo.History title="중국 Social Account 도입 및 중국 브라우저 Cross Browsing 진행">
            중국 유저들이 주로 사용하는 Weibo, QQ, 치타 등 다양한 브라우저들에 대한 Cross Browsing 진행 및 중국 SNS
            서비스와의 OAuth 2.0 연동.
          </OrganizationInfo.History>
        </OrganizationInfo>
      </Box>
      <Box component="section">
        <SectionTitle title="Side Project" />
        <OrganizationInfo
          logo={imageData.lubyconLogo.childImageSharp.fluid}
          title="Lubycon"
          description="루비콘은 2014년 친한 친구들이 모여 결성된 팀으로, 여러 이해관계에서 벗어나 해보고 싶은 일을 마음껏 해보자는 취지로 운영되고 있는 팀 입니다."
          startDate={new Date(2015, 4, 1)}
          role="Frontend Engineer"
          link="https://lubycon.io"
          logger={logger}
        >
          <OrganizationInfo.History
            title="멘토링 프로젝트"
            links={[{ type: 'demo', url: 'https://lubycon.io/mentoring/intro/' }]}
          >
            주니어 개발자와 디자이너를 대상으로 하는 멘토링 프로젝트입니다. 3개월 동안 함께 토이프로젝트를 개발하며
            커뮤니케이션, MVP 정의, 가설 검증 등 효율적인 협업을 위해 필요한 소프트스킬과 챕터 내에서의 코드 리뷰,
            디자인 크리틱을 통한 하드스킬 모두 성장하는 것을 목표로 합니다.
          </OrganizationInfo.History>
          <OrganizationInfo.History
            title="Sandwhichi Frontend, Backend 개발"
            links={[{ type: 'github', url: 'https://github.com/Lubycon/sandwhichi-frontend' }]}
          >
            중고같은 신입이 되기 위해, 또는 공부를 위해 사이드 프로젝트를 찾는 사람들을 위한 서비스. 조건에 맞는 토이
            프로젝트를 찾거나 사람을 찾아서 프로젝트를 진행할 수 있도록 도와주고 싶은 마음에 시작한 사이드 프로젝트.
            <br />
            FE는 Vue와 TS, BE는 Express와 일부 API는 AWS Lambda를 사용한 Serverless API로 구현.
          </OrganizationInfo.History>
          <OrganizationInfo.History
            title="Pixelstairs Frontend 개발"
            links={[{ type: 'github', url: 'https://github.com/Lubycon/pixelstairs-web-app-angular' }]}
          >
            사이드 프로젝트로 제작했던 Lubycon프로젝트가 너무 방대한 코드로 인해 회사다니면서 유지 보수가 불가능해짐을
            직감하고 Artwork 부분만 따로 떼어내서 작업해보기로 한 서비스. AngularJS를 사용하였고, 처음으로 AWS와
            Docker를 사용하여 서비스를 제공하기 시작.
          </OrganizationInfo.History>
          <OrganizationInfo.History
            title="Web 3D Model Editor"
            links={[
              {
                type: 'github',
                url: 'https://github.com/Lubycon/legacy-lubycon-php/tree/master/lubycon/src/pages/view/editor/3d',
              },
            ]}
          >
            WebGL 기반의 3D 모델 웹 뷰어 및 에디터 개발. OBJ 파일을 JSON 형태로 파싱하는 Parser, Geometry에 텍스처 적용,
            내부 광원 생성 및 Geometry의 질감과 스펙큘라 설정 등의 기능을 구현.
          </OrganizationInfo.History>
          <OrganizationInfo.History
            title="Lubycon Frontend, Backend 개발"
            links={[
              {
                type: 'github',
                url: 'https://github.com/Lubycon/2015-1st-lubycon',
              },
            ]}
          >
            jQuery, AngularJS, Laravel을 기반으로 한 Artwork, Vector source, 3d model를 웹 상에 업로드하고 공유하는
            서비스를 개발.
          </OrganizationInfo.History>
        </OrganizationInfo>
      </Box>
      <Box component="section">
        <SectionTitle title="Projects" />
        <ProjectItem
          title="Solar System TS"
          links={[
            {
              type: 'github',
              url: 'https://github.com/evan-moon/solarsystemts',
            },
            {
              type: 'demo',
              url: 'https://evan-moon.github.io/solarsystemts/',
            },
          ]}
          posts={solarSystemPosts}
          logger={logger}
        >
          <ProjectItem.Description>
            TypeScript, Vue.js, Three.js(WebGL)을 사용해 제작한 실시간 태양계 시뮬레이터. 케플러 궤도 6요소와 케플러
            방정식 + 레가르 다항식을 사용하여 현재 날짜에 해당 행성이 어디에 있는지 위치를 추적하는 방향으로
            구현하였습니다.
          </ProjectItem.Description>
        </ProjectItem>
        <ProjectItem
          title="Simple ANN"
          links={[
            {
              type: 'github',
              url: 'https://github.com/evan-moon/simple-ann',
            },
            {
              type: 'demo',
              url: 'https://evan-moon.github.io/simple-ann/',
            },
          ]}
          posts={simpleAnnPosts}
          logger={logger}
        >
          <ProjectItem.Description>
            TypeScript와 React, D3, ChartJS를 사용해 제작한 간단한 인공 신경망. 기초적인 Sigmoid, MSE 등의 알고리즘을
            사용하여 구현되었으며, 레이어의 개수와 노드의 개수, 학습 횟수 등을 설정하면 학습 과정에서의 y값의 변화와
            Loss의 변화를 시각화하여 확인할 수 있습니다.
            <br />
            <br />
            추후 여러 개의 Activation Function을 선택할 수 있는 기능과 레이어마다 노드의 개수를 다르게 하는 등 네트워크
            커스터마이징을 좀 더 다채롭게 할 수 있는 기능을 추가할 예정입니다.
          </ProjectItem.Description>
        </ProjectItem>
        <ProjectItem
          title="Simple Waveform Visualizer"
          links={[
            {
              type: 'github',
              url: 'https://github.com/evan-moon/simple-waveform-visualizer',
            },
            {
              type: 'demo',
              url: 'https://evan-moon.github.io/simple-waveform-visualizer/',
            },
          ]}
          posts={simpleWaveformPosts}
          logger={logger}
        >
          <ProjectItem.Description>
            오디오 파일을 웹 상에 업로드하여 여러가지 오디오 이펙터를 걸어볼 수 있는 어플리케이션. 현재 개발된 이펙터는
            Compressor, Reverb, Delay, Filter (LPF, HPF), EQ, Distortion, Tremolo 정도이며, 각 이펙터는 오디오
            이펙터로써의 기본적인 기능만 가지고 있는 상태입니다.
            <br />
            <br />
            다음 단계는 오실레이터를 사용하여 신디사이저를 만들거나, 트랙을 나누어서 동시에 여러 오디오 소스를 재생하며
            이펙터를 사용할 수 있도록 변경할 예정입니다.
          </ProjectItem.Description>
        </ProjectItem>
        <ProjectItem
          title="Zarbis"
          links={[
            {
              type: 'github',
              url: 'https://github.com/evan-moon/zarbis',
            },
            {
              type: 'demo',
              url: 'https://evan-moon.github.io/zarbis/',
            },
          ]}
          logger={logger}
        >
          <ProjectItem.Description>
            시계 + 날씨 + 미세먼지 정보 + 이쁜 사진 대시보드.
            <br />
            <br />
            집에 노트북이 남길래 하나를 대시보드 전용으로 쓰려고 만든 웹 어플리케이션. 크롬 익스텐션인{' '}
            <Link
              href="https://chrome.google.com/webstore/detail/momentum/laookkfknpbbblfpciffpaejjkokdgca?hl=ko&utm_source"
              target="_blank"
            >
              Momentum
            </Link>
            을 쓰고 있었지만 비염이 심한 본인을 위해 미세먼지 농도도 함께 보고 싶었는데 얘네가 해당 기능 제공을 안해줘서
            하나 새로 만들어서 쓰는 중 입니다.
            <br />
            <br />
            배경사진은 날씨에 따라 알맞은 사진으로 변경되어 사용자가 굳이 구석에 있는 날씨 정보를 눈여겨 보지 않더라도
            한 눈에 현재 날씨를 알아볼 수 있도록 하였습니다.
          </ProjectItem.Description>
        </ProjectItem>
      </Box>
      <Box component="section">
        <SectionTitle title="저서 및 강의" />
        <ProjectItem
          title="시장에서 살아남는 개발자 되기 | 2023 NERDINARY CONF"
          links={[
            {
              type: 'youtube',
              url: 'https://www.youtube.com/watch?v=BuU7JVune-s&t=17s',
            },
          ]}
          posts={surviveMarketPosts}
          logger={logger}
        >
          2023년 9월 8일 - 9월 9일에 개최된 2023 NE(O)RDINARY CONFERENCE에서 발표한 내용입니다.
          <br />
          <br />
          2021년에 비해 현재 2023년의 시장 상황은 많이 바뀌었지만, 정작 개발자로써 일을 시작한지 얼마 되지 않은 분들에게
          이러한 현실에 대한 이야기를 해주시는 분들이 많지 않다고 느껴, 조금은 특이한 발표 주제를 정하게 되었습니다.
        </ProjectItem>
        <ProjectItem
          title="정규식과 함께 생산성 200%, 2x 엔지니어가 되어보자"
          links={[
            {
              type: 'shop',
              url: 'https://inf.run/q7vV',
            },
          ]}
          posts={regexPosts}
          logger={logger}
        >
          <ProjectItem.Description>
            이 강의에는 정규식이 제공하는 간단한 기능들과, 제가 실무에서 직접 접했던 문제들을 어떻게 이 기능들을 사용해
            효율적으로 해결할 수 있었는지에 대한 내용을 담았습니다.
            <br />
            <br />더 나아가 단순히 정규식의 기능만을 나열하는 것이 아니라 정규식이라는 기술을 누가, 어떠한 이유로 만들게
            되었는지 그 원리까지 재미있게 설명합니다.
          </ProjectItem.Description>
        </ProjectItem>
        <ProjectItem
          title="커피 한 잔 마시며 끝내는 VueJS"
          links={[
            {
              type: 'github',
              url: 'https://github.com/CanDoVueJS',
            },
            {
              type: 'shop',
              url: 'http://yes24.com/Product/Goods/76639545',
            },
          ]}
          posts={bookPosts}
          logger={logger}
        >
          <ProjectItem.Description>
            이 책에서는 VueJS에 대한 API뿐만 아니라 어떻게 활용하면 되는지에 대해 효과적으로 설명합니다. 이러한 활용
            예제는 실전 애플리케이션을 구축해나가는 과정을 쉽고 효과적으로 학습하도록 도와줍니다.
            <br />
            <br />
            어플리케이션은 실무와 동일하게 REST API를 제공하는 백엔드와 통신을 통해 데이터를 받아온 후 클라이언트에서
            상태 관리를 하는 과정으로 진행되며, 이때 필요한 REST API는 Github을 통해 프로젝트를 클론받는 방식으로
            제공하고 있습니다.
            <br />
            <br />그 뿐만 아니라 실전 애플리케이션을 구축해나가는 과정에서 겪을 수 있는 트러블 슈팅과 필자의 실무 경험을
            바탕으로 하는 조언도 함께 학습합니다.
          </ProjectItem.Description>
        </ProjectItem>
      </Box>
    </Layout>
  );
};

export default AboutPage;
