import React, { ReactNode, useCallback } from 'react';
import { Box, Typography, Grid, makeStyles, Theme, Divider } from '@material-ui/core';

import SimplePostItem from 'components/Items/SimplePostItem';
import { Post } from 'models/post';
import { Logger } from 'utils/loggers';
import { Link } from './models';
import ExternalLink from './ExternalLink';

const useStyles = makeStyles<Theme>(({ shape, spacing, typography }) => ({
  root: {
    marginBottom: spacing(6),
  },
  thumbnail: {
    minWidth: 400,
    borderRadius: shape.borderRadius,
    marginRight: spacing(2),
  },
  title: {
    fontWeight: typography.fontWeightBold,
    fontSize: typography.h6.fontSize,
  },
  description: {
    marginBottom: spacing(4),
  },
  relatedPostsWrapper: {
    marginLeft: spacing(2),
    marginTop: spacing(2),
  },
  divider: {
    margin: `${spacing(2)}px 0`,
  },
}));

interface Props {
  title: string;
  children: ReactNode;
  links?: Link[];
  posts?: Post[];
  logger?: Logger;
}
const ProjectItem = ({ title, children, links = [], posts = [], logger }: Props) => {
  const styles = useStyles();

  const handleExternalLinkClick = useCallback(
    (type: string) => {
      logger?.click(`click_external_link`, {
        product: title,
        type,
      });
    },
    [logger, title]
  );
  const handlePostClick = useCallback(
    (path: string) => {
      logger?.click('click_related_posting', {
        path,
      });
    },
    [logger]
  );

  return (
    <div className={styles.root}>
      <Box>
        <Box>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography className={styles.title} component="h3">
                {title}
              </Typography>
            </Grid>
            <Grid container item xs>
              {links.map(link => (
                <ExternalLink key={link.url} link={link} onClick={handleExternalLinkClick} />
              ))}
            </Grid>
          </Grid>
        </Box>
        <Grid className={styles.description} item xs={12}>
          {children}
        </Grid>
        {posts.length > 0 && (
          <Box className={styles.relatedPostsWrapper}>
            {posts.map(({ frontmatter, fields }, index) => (
              <Box key={index}>
                <SimplePostItem
                  thumbnail={frontmatter.thumbnail.childImageSharp?.fixed?.src ?? ''}
                  title={frontmatter.title ?? ''}
                  date={frontmatter.date}
                  path={fields.path ?? '/'}
                  categories={frontmatter.categories}
                  onClick={handlePostClick}
                />
                {index !== posts.length - 1 && (
                  <Box className={styles.divider}>
                    <Divider variant="inset" />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </div>
  );
};

const Description = ({ children }: { children: ReactNode }) => <Typography variant="body2">{children}</Typography>;

ProjectItem.Description = Description;
export default ProjectItem;
