import React from 'react';
import { useProfileInfo } from 'hooks/useProfileInfo';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Image from 'gatsby-image';
import MySocials from 'components/Utils/MySocials';

const useStyles = makeStyles<Theme>(({ spacing, shape, breakpoints, typography }) => ({
  root: {
    marginTop: spacing(6),
  },
  profileImage: {
    width: 200,
    height: 200,
    marginRight: spacing(2),
    borderRadius: shape.borderRadius,
    [breakpoints.down('md')]: {
      display: 'none',
    },
  },
  header: {
    marginBottom: spacing(3),
  },
  title: {
    [breakpoints.down('md')]: {
      fontSize: typography.h4.fontSize,
    },
  },
  description: {
    marginBottom: spacing(4),
  },
}));

interface Props {
  onClickExternalLink?: (name: string) => void;
}
const IntroSection = ({ onClickExternalLink }: Props) => {
  const styles = useStyles();
  const { avatar } = useProfileInfo();

  return (
    <Box className={styles.root} component="section">
      <Grid container className={styles.header} alignItems="flex-end">
        <Grid item xs={12} lg>
          <Typography className={styles.title} variant="h2" component="h1">
            안녕하세요.
            <br />
            개발자 <strong>문동욱</strong>입니다.
          </Typography>
        </Grid>
        <Grid item xs="auto" component="a" href={avatar.childImageSharp.fluid?.src} target="_blank">
          <Image className={styles.profileImage} fluid={avatar.childImageSharp.fluid} />
        </Grid>
      </Grid>
      <Typography className={styles.description}>
        오늘 내가 만든 프로그램이 누군가에게 도움을 줄 수 있다는 사실에서 동기를 얻습니다. 아이디어가 제 손을 통해
        현실화되고, 그렇게 현실화된 프로덕트를 통해 사용자가 해피모먼트를 경험하는 것을 보면 보람을 느낍니다.
      </Typography>
      <MySocials tooltipPlacement="bottom" onClick={onClickExternalLink} />
    </Box>
  );
};

export default IntroSection;
