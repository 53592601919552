import { useStaticQuery, graphql } from 'gatsby';
import { Post } from 'models/post';
import { GroupItem } from 'models/query';
import { useRecoilState } from 'recoil';
import { languageAtom } from 'atoms/language';
import { useMemo } from 'react';

interface QueryResult {
  allMarkdownRemark: {
    group: Array<GroupItem<Post>>;
  };
}
export const useRelatedProjectPosts = () => {
  const [lang] = useRecoilState(languageAtom);
  const { allMarkdownRemark }: QueryResult = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___relatedProject) {
          fieldValue
          totalCount
          nodes {
            ...PostQueryFragment
          }
        }
      }
    }
  `);

  const group = useMemo(() => {
    return allMarkdownRemark.group.map(g => {
      return {
        ...g,
        nodes: g.nodes.filter(post => post.fields.lang === lang),
      };
    });
  }, [allMarkdownRemark, lang]);

  return group;
};
